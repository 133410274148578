<template>
  <div class="card">
    <IntersectionOberverLoading
      :defaultShowServer="true"
      @onScroll="handleIntersection"
      style="top: 0; height: 0px"
    />
    <div class="card_title">
      <div class="img_wrap">
        <img :src="background" alt="" />
      </div>
    </div>
    <div class="card_list">
      <template v-for="(goods, goodsIndex) in list">
        <goods-card-activity
          v-if="goods.isAct"
          :data="goods"
          :key="goods.id"
          :styles="{
            marginRight: Number(goodsIndex + 1) % 5 === 0 ? '0px' : '8px',
          }"
        />
        <goods-card
          v-else
          :data="goods"
          :key="goods.id"
          :styles="{
            marginRight: Number(goodsIndex + 1) % 5 === 0 ? '0px' : '8px',
          }"
        />
      </template>
    </div>
  </div>
</template>

<script>
import goodsCard from "../components/goodsCard";
import goodsCardActivity from "../components/goodsCardActivity.vue"
import IntersectionOberverLoading from "@/components/IntersectionOberverLoading/index.vue";
import request from "@/utils/request";
import {mapGetters} from "vuex"
import qs from "qs";
export default {
  components: {
    goodsCard,
    goodsCardActivity,
    IntersectionOberverLoading,
  },
  props: {
    config: Object,
    title: String,
    background: String,
    tempType:String //模版id
  },
  data() {
    return {
      list: [],
      load: true,
      page: {
        pageId: 1,
        pageSize: 50,
      },
    };
  },
  computed: {
    ...mapGetters(['marketGroupConfig'])
  },
  methods: {
    getList() {
      if (this.config?.url) {
        let params = {
          ...this.page,
        };
        if (this.config.type == 2) {
          let arr = this.config.url.split("?");
          let domain = arr[0];
          let obj = qs.parse(arr[1]);
          request(domain, {
            method: "GET",
            isOpenApi: true,
            data: {
              ...params,
              ...obj,
            },
          }).then((res) => {
            if (res && res.code === 0) {
              this.list = this.tempType == 5 ?  res.data.list.slice(0,50): res.data.list.slice(0, 15);
              this.load = true;
            }
          });
          return;
        } else {
          request(this.config.url, {
            method: "GET",
            data: {
              ...params,
            },
          }).then((res) => {
            if (res && res.code === 1) {
              this.list = this.tempType == 5 ?  res.data.list.slice(0,50): res.data.list.slice(0, 15);
              this.load = true;
            }
          });
        }
      }
      return [];
    },
    handleIntersection() {
      if (!this.load) return;
      this.load = false;
      if (!this.list.length) {
        this.getList();
      }
    },
    setActivity(state){
      if(!state){
        return false;
      }
      const activity = Object.keys(state);
      let newData = this.list.map(item => {
        activity.forEach(a => {
          if (item.marketGroup[0]  == a && item.quanMLink > 0) {
            item.isAct = true
            return false
          } else {
            item.isAct = false
          }
        })
        
        return item
      })
      this.list = [...newData]
    }
  },
  mounted() {
    // this.getList()
  },
  watch:{
    load(state){
      if(state){
        this.setActivity(this.marketGroupConfig)
      }
    }
  }
};
</script>

<style lang="less" scoped>
.card {
  padding-top: 30px;
  position: relative;
  .card_title {
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;
    // min-width: 1200px;
    .img_wrap {
      width: 3000px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
    }
  }
  .card_list {
    width: 1032px;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }
}
</style>

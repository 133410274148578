var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("IntersectionOberverLoading", {
        staticStyle: { top: "0", height: "0px" },
        attrs: { defaultShowServer: true },
        on: { onScroll: _vm.handleIntersection }
      }),
      _c("div", { staticClass: "card_title" }, [
        _c("div", { staticClass: "img_wrap" }, [
          _c("img", { attrs: { src: _vm.background, alt: "" } })
        ])
      ]),
      _c(
        "div",
        { staticClass: "card_list" },
        [
          _vm._l(_vm.list, function(goods, goodsIndex) {
            return [
              goods.isAct
                ? _c("goods-card-activity", {
                    key: goods.id,
                    attrs: {
                      data: goods,
                      styles: {
                        marginRight:
                          Number(goodsIndex + 1) % 5 === 0 ? "0px" : "8px"
                      }
                    }
                  })
                : _c("goods-card", {
                    key: goods.id,
                    attrs: {
                      data: goods,
                      styles: {
                        marginRight:
                          Number(goodsIndex + 1) % 5 === 0 ? "0px" : "8px"
                      }
                    }
                  })
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="goods_card" :style="{ ...styles }" @click="toDetail(data.goodsId)">
    <div class="pic">
      <img v-lazy="`${data.mainPic}_310x310.jpg`" alt />
      <div class="pic_tag" v-if="data.specialText[0]">
        {{ data.specialText[0] }}
      </div>
    </div>
    <div class="goods_card_content">
      <div class="goods_card_title">
        <img
          :src="getShopImg(data.haitao, data.tchaoshi, data.shopType)"
          alt=""
          v-if="getShopImg(data.haitao, data.tchaoshi, data.shopType)"
        />
        <span>{{ data.dtitle || data.title }}</span>
      </div>
      <div class="goods_card_price">
        <span class="curr">￥</span>
        <span class="price">{{ data.actualPrice }}</span>
        <span class="text">券后价</span>
      </div>
      <div class="goods_card_sale">
        <span class="origin">
          <span>{{ data.shopType == 1 ? "天猫价" : "淘宝价" }}</span>
          <span>￥{{ data.originalPrice }}</span>
        </span>
        <span class="sale"> 已售{{ data.monthSales | salesNum(_,1) }}件 </span>
      </div>
      <div class="goods_card_foot" v-if="data.couponPrice">
        <div class="quan">领券直降{{ data.couponPrice }}元</div>
        <a class="btn" @click="toDetail(data.goodsId)">
          立即抢购
        </a>
      </div>
      <div class="goods_card_foot_noquan" v-else>
        <a class="btn" @click="toDetail(data.goodsId)">
          立即抢购
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// import { DigitalAbbNumber } from '@/utils/utils'
export default {
  props: {
    data: Object,
    styles: Object,
  },
  data() {
    return {};
  },
  methods: {
    toDetail(id) {
      try {
        console.log({
          desc: "运营配置栏目点击",
          columnId: this.$route.query.id,
          objectId: id,
        });
        DTKsatc.eventStat({
          name: "moveColumnClickEvent",
          params: {
            desc: "运营配置栏目点击",
            columnId: this.$route.query.id,
            objectId: id,
          },
        });
      } catch (err) {}
      window.open(`?r=/l/d&id=${id}`,'_blank');
    },
    getShopImg(haitao, tchaoshi, shopType) {
      if (haitao == 1) return require("../../../assets/search/prod_icon2.png");
      if (tchaoshi == 1)
        return require("../../../assets/search/prod_icon3.png");
      if (shopType == 1)
        return require("../../../assets/search/prod_icon1.png");
      return null;
    },
  },
};
</script>

<style lang="less" scoped>
.goods_card {
  width: 200px;
  // height: 357px;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 8px;
  border-radius: 8px;
  cursor: pointer;
  .pic {
    margin: 0;
    height: 200px;
    position: relative;
    .pic_tag {
      position: absolute;
      height: 25px;
      bottom: 0;
      line-height: 25px;
      padding: 0 10px;
      background: rgba(255, 122, 61, 0.9);
      border-top-right-radius: 10px;
      font-size: 12px;
      color: #ffffff;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .goods_card_content {
    padding: 0 10px 10px 10px;
  }
  .goods_card_title {
    display: flex;
    overflow: hidden;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 9px;
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-left: 2px;
      font-size: 15px;
      color: #333;
      line-height: 20px;
    }
    img {
      width: 14px;
      height: 14px;
      flex-shrink: 0;
    }
  }
  .goods_card_price {
    margin-top: 12px;
    .curr {
      font-size: 18px;
      color: #fe2e2e;
    }
    .price {
      font-size: 26px;
      color: #fe2e2e;
      font-family: ArialMT;
    }
    .text {
      margin-left: 3px;
      font-size: 13px;
      color: #fe2e2e;
      font-family: PingFangSC-Medium;
      font-weight: bold;
    }
  }
  .goods_card_sale {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #ababab;
    margin: 8px 0 12px 0;
    font-size: 13px;
    .origin {
      text-decoration: line-through;
    }
    .sale {
      font-family: PingFangSC-Regular;
      font-weight: Regular;
      font-size: 13px;
      color: #ababab;
    }
  }
  .goods_card_foot {
    display: flex;
    align-items: flex-end;
    .quan {
      width: 100px;
      height: 22px;
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      background: #ffeddb;
      color: #f14242;
    }
    .btn {
      flex: 1;
      height: 28px;
      font-size: 12px;
      color: #ffffff;
      line-height: 28px;
      text-align: center;
      background: #f14242;
    }
  }
  .goods_card_foot_noquan{
    width: 180px;
    height: 28.4px;
    background: #F14242;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
}
</style>

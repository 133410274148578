<template>
  <div class="temp_banner swiper-container" id="temp_banner">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in list" :key="index">
        <img :src="item.pic" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { importCDN } from "@/utils/utils";
export default {
  props: {
    list: [],
  },
  data() {
    return {};
  },
  methods: {
    init() {
      importCDN("https://public.ffquan.cn/lib/swiper/js/swiper.min.js").then(
        () => {
          this.mySwiper = new Swiper(".temp_banner", {
            slidesPerView: 1,
            resistanceRatio: 0,
          });
        }
      );
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
@import "https://public.ffquan.cn/lib/swiper/css/swiper.min.css";
.temp_banner {
  width: 100%;
  img {
    width: 100%;
  }
}
</style>

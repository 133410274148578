var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "goods_card",
      style: Object.assign({}, _vm.styles),
      on: {
        click: function($event) {
          return _vm.toDetail(_vm.data.goodsId)
        }
      }
    },
    [
      _c("div", { staticClass: "pic" }, [
        _c("img", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy",
              value: _vm.data.mainPic + "_310x310.jpg",
              expression: "`${data.mainPic}_310x310.jpg`"
            }
          ],
          attrs: { alt: "" }
        }),
        _vm.data.specialText[0]
          ? _c("div", { staticClass: "pic_tag" }, [
              _vm._v(" " + _vm._s(_vm.data.specialText[0]) + " ")
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "goods_card_content" }, [
        _c("div", { staticClass: "goods_card_title" }, [
          _vm.getShopImg(_vm.data.haitao, _vm.data.tchaoshi, _vm.data.shopType)
            ? _c("img", {
                attrs: {
                  src: _vm.getShopImg(
                    _vm.data.haitao,
                    _vm.data.tchaoshi,
                    _vm.data.shopType
                  ),
                  alt: ""
                }
              })
            : _vm._e(),
          _c("span", [_vm._v(_vm._s(_vm.data.dtitle || _vm.data.title))])
        ]),
        _c("div", { staticClass: "goods_card_price" }, [
          _c("span", { staticClass: "curr" }, [_vm._v("￥")]),
          _c("span", { staticClass: "price" }, [
            _vm._v(_vm._s(_vm.data.actualPrice))
          ]),
          _c("span", { staticClass: "text" }, [_vm._v("券后价")])
        ]),
        _c("div", { staticClass: "goods_card_sale" }, [
          _c("span", { staticClass: "origin" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.data.shopType == 1 ? "天猫价" : "淘宝价"))
            ]),
            _c("span", [_vm._v("￥" + _vm._s(_vm.data.originalPrice))])
          ]),
          _c("span", { staticClass: "sale" }, [
            _vm._v(
              " 已售" +
                _vm._s(_vm._f("salesNum")(_vm.data.monthSales, _vm._, 1)) +
                "件 "
            )
          ])
        ]),
        _vm.data.couponPrice
          ? _c("div", { staticClass: "goods_card_foot" }, [
              _c("div", { staticClass: "quan" }, [
                _vm._v("领券直降" + _vm._s(_vm.data.couponPrice) + "元")
              ]),
              _c(
                "a",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      return _vm.toDetail(_vm.data.goodsId)
                    }
                  }
                },
                [_vm._v(" 立即抢购 ")]
              )
            ])
          : _c("div", { staticClass: "goods_card_foot_noquan" }, [
              _c(
                "a",
                {
                  staticClass: "btn",
                  on: {
                    click: function($event) {
                      return _vm.toDetail(_vm.data.goodsId)
                    }
                  }
                },
                [_vm._v(" 立即抢购 ")]
              )
            ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }